import './App.css';
import React, { Component } from 'react';
import { SocialIcon } from 'react-social-icons';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import os from './assets/opensea.png';
import twitter from './assets/twitter.png';
import logo from './assets/logo.jpg'
import header from './assets/header.png';
import Carousel from './components/carousel';
import copy from './assets/copy.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";

var Scroll = require('react-scroll');
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;


const opensea = () => {
	window.open("https://opensea.io/collection/dozy-tank-creatures-nft");
}

const tweet = () => {
	window.open("#");
}

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let mintStatus = ''
let cost = '';
let wlCost = '';
let max_per_wallet = '';
let publicMintMsg = "Public Mint";
let refAddress;

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_initBaseURI",
		  "type": "string"
		},
		{
		  "internalType": "string",
		  "name": "_initNotRevealedUri",
		  "type": "string"
		},
		{
		  "internalType": "string",
		  "name": "_contractURI",
		  "type": "string"
		}
	  ],
	  "stateMutability": "nonpayable",
	  "type": "constructor"
	},
	{
	  "inputs": [],
	  "name": "ApprovalCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApprovalQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApprovalToCurrentOwner",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "ApproveToCaller",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "BalanceQueryForZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintZeroQuantity",
	  "type": "error"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		}
	  ],
	  "name": "OperatorNotAllowed",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnerIndexOutOfBounds",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "OwnerQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TokenIndexOutOfBounds",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferCallerNotOwnerNorApproved",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferFromIncorrectOwner",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToNonERC721ReceiverImplementer",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "TransferToZeroAddress",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "URIQueryForNonexistentToken",
	  "type": "error"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "approved",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Approval",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "indexed": false,
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "ApprovalForAll",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Transfer",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "OPERATOR_FILTER_REGISTRY",
	  "outputs": [
		{
		  "internalType": "contract IOperatorFilterRegistry",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "approve",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		}
	  ],
	  "name": "balanceOf",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "baseURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "contractURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "decimalNumber",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "getApproved",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		}
	  ],
	  "name": "isApprovedForAll",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "max_per_wallet",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "quantity",
		  "type": "uint256"
		},
		{
		  "internalType": "address",
		  "name": "ref",
		  "type": "address"
		}
	  ],
	  "name": "mint",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "name",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "notRevealedUri",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "ownerOf",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "publicSaleCost",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "public_mint_status",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "refRewardAmount",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "revealed",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_tokenId",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "_salePrice",
		  "type": "uint256"
		}
	  ],
	  "name": "royaltyInfo",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes",
		  "name": "data",
		  "type": "bytes"
		}
	  ],
	  "name": "safeTransferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "operator",
		  "type": "address"
		},
		{
		  "internalType": "bool",
		  "name": "approved",
		  "type": "bool"
		}
	  ],
	  "name": "setApprovalForAll",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_newBaseURI",
		  "type": "string"
		}
	  ],
	  "name": "setBaseURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_contractURI",
		  "type": "string"
		}
	  ],
	  "name": "setContractURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_decimalNumber",
		  "type": "uint256"
		}
	  ],
	  "name": "setDecimalNumber",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_MAX_SUPPLY",
		  "type": "uint256"
		}
	  ],
	  "name": "setMAX_SUPPLY",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_max_per_wallet",
		  "type": "uint256"
		}
	  ],
	  "name": "setMax_per_wallet",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "string",
		  "name": "_notRevealedURI",
		  "type": "string"
		}
	  ],
	  "name": "setNotRevealedURI",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_publicSaleCost",
		  "type": "uint256"
		}
	  ],
	  "name": "setPublicSaleCost",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "_refRewardAmount",
		  "type": "uint256"
		}
	  ],
	  "name": "setRefRewardAmount",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_receiver",
		  "type": "address"
		},
		{
		  "internalType": "uint96",
		  "name": "_royaltyFeesInBips",
		  "type": "uint96"
		}
	  ],
	  "name": "setRoyaltyInfo",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "_tokenContract",
		  "type": "address"
		}
	  ],
	  "name": "setTokenContract",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes4",
		  "name": "interfaceId",
		  "type": "bytes4"
		}
	  ],
	  "name": "supportsInterface",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "symbol",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggleReveal",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "toggle_public_mint_status",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "token",
	  "outputs": [
		{
		  "internalType": "contract IERC20",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "index",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenByIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "owner",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "index",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenOfOwnerByIndex",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "tokenURI",
	  "outputs": [
		{
		  "internalType": "string",
		  "name": "",
		  "type": "string"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "tokenWithdrawal",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "totalSupply",
	  "outputs": [
		{
		  "internalType": "uint256",
		  "name": "",
		  "type": "uint256"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "transferFrom",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "withdraw",
	  "outputs": [],
	  "stateMutability": "payable",
	  "type": "function"
	}
];

const address = "0x289ea73A1FE602f2A8C4BccecD860b963FcE87e9";

let contract;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Free Mint',
		_publicMintMsg: 'Mint Here',
		_mintStatus: false,
		_cost: '',
		_wlCost: '',
		_wlMintAmount: '',
		_max_per_wallet: '',
		_owner: '',
		_myRefLink: '',
		_refAddress: ''
	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (totalSupplyNFT < 10000) {

				onlyLeft = max_per_wallet - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 10000) {

				onlyLeft = max_per_wallet - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);


		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					if( refAddress == undefined){
					this.setState({ _refAddress : account });
					refAddress = account
					}
					
					console.log("refAddress:" + refAddress);

					if (publicSale * 1 + mintAmount * 1 <= max_per_wallet) {

						this.setState({ statusError: false, statusLoading: true });
						await contract.methods.mint(mintAmount * 1, refAddress).send({ maxPriorityFeePerGas: 40000000000, gasLimit:685000, from: account, value: this.state.totalValue * 1 });
						this.setState({ statusLoading: false, success: true });
						console.log("Mint Amount :" + mintAmount);

					} else {
						publicMintMsg = "Mint Limit Reached"
						this.setState({ _publicMintMsg: publicMintMsg });
					}

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}

			} else {

				try {

					console.log("refAddress:" + refAddress);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1, account).send({ maxPriorityFeePerGas: 40000000000, gasLimit:685000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + mintAmount);



				} catch (err) {

					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}
			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();
 
		const providerOptions = {

			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Tree Poet", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 137, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}

		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {
						console.log("Function - walletConnect : " + web3Modal.cachedProvider);
						const chainId = 137;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],

											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });
							console.log("max_per_wallet:" + max_per_wallet);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							let url = "https://dtc-mint.pinkribbons.club/?ref=";
							let refLink = url.concat(account);
							this.setState({ _myRefLink: refLink });

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = max_per_wallet - publicSale;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = max_per_wallet - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						} catch (err) {
							console.log("err: " + err);
						}
					} else if (web3Modal.cachedProvider == "coinbasewallet") {

						const chainId = 137;


						if (CoinbaseWalletProvider.networkVersion !== chainId) {
							try {
								await CoinbaseWalletProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/
												
											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });
							console.log("max_per_wallet:" + max_per_wallet);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							let url = "https://dtc-mint.pinkribbons.club/?ref=";
							let refLink = url.concat(account);
							this.setState({ _myRefLink: refLink });

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = max_per_wallet - publicSale;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = max_per_wallet - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						}  catch (err) {
							console.log("err: " + err);

						}
					} else if (web3Modal.cachedProvider == "walletconnect") {

						const chainId = 137;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await CoinbaseWalletProvider.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Polygon Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Polygon Mainnet', decimals: 18, symbol: 'MATIC' },
												rpcUrls: ['https://polygon-rpc.com/'],

												/*chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],*/

											},
										],
									});
								}
							}
						}

						try {

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							max_per_wallet = await contract.methods.max_per_wallet().call();
							this.setState({ _max_per_wallet: max_per_wallet });
							console.log("max_per_wallet:" + max_per_wallet);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							let url = "https://dtc-mint.pinkribbons.club/?ref=";
							let refLink = url.concat(account);
							this.setState({ _myRefLink: refLink });

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = max_per_wallet - publicSale;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = max_per_wallet - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


						}  catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	whitelistMint = async event => {

		event.preventDefault();


		//	console.log('Whitelist Merkle Tree\n', merkleTree.toString());
		console.log("Root Hash: ", rootHash);
		console.log("Root HashHash: ", rootHashHash);

		// ***** ***** ***** ***** ***** ***** ***** ***** // 

		// CLIENT-SIDE: Use `msg.sender` address to query and API that returns the merkle proof
		// required to derive the root hash of the Merkle Tree

		// ✅ Positive verification of address
		//const claimingAddress = leafNodes[0];
		//console.log("Claiming Address:"+ claimingAddress);
		// ❌ Change this address to get a `false` verification
		const claimingAddress = keccak256(account);

		// `getHexProof` returns the neighbour leaf and all parent nodes hashes that will
		// be required to derive the Merkle Trees root hash.
		const hexProof = merkleTree.getHexProof(claimingAddress);
		console.log("HexProof:" + hexProof);

		// ✅ - ❌: Verify is claiming address is in the merkle tree or not.
		// This would be implemented in your Solidity Smart Contract
		console.log("Final result: " + merkleTree.verify(hexProof, claimingAddress, rootHash));
		FinalResult = merkleTree.verify(hexProof, claimingAddress, rootHash);
		this.setState({ _FinalResult: FinalResult });
		let wl_value = this.state._wlMintAmount * 1 * wlCost;
		let total_wl_mintings = publicSale * 1 + this.state._wlMintAmount * 1;
		console.log("total_wl_mintings:" + total_wl_mintings);
		console.log("publicSale:" + publicSale);
		console.log("wlMintAmount:" + this.state._wlMintAmount);

		if (total_wl_mintings <= max_per_wallet) {
			if (FinalResult) {
				try {
					await contract.methods.whitelistMint(hexProof, this.state._wlMintAmount).send({ gasLimit: 385000, from: account, value: wl_value });

				} catch (err) {
					console.log(err);
				}
			} else {
				wlMsg = "Not Eligible for WL"
				this.setState({ _wlMsg: wlMsg });
			}

		} else {
			wlMsg = "WL Limit Reached"
			this.setState({ _wlMsg: wlMsg });
		}

	}

	freeMint = async event => {

		event.preventDefault();

		console.log("publicSale:" + publicSale);
		console.log("wlMintAmount:" + this.state._wlMintAmount);


		if (publicSale * 1 + this.state._wlMintAmount * 1 <= max_per_wallet) {
			try {

				await contract.methods.freeMint(this.state._wlMintAmount * 1).send({ gasLimit: 385000, from: account, value: 0 });
				console.log("Mint Amount :" + this.state._wlMintAmount);



			} catch (err) {

				console.log(err);

			}

		} else {
			wlMsg = "Limit Reached"
			this.setState({ _wlMsg: wlMsg });
		}

	}

	async componentDidMount() {

		try {
			if (window.location.href != null) {

				let currentUrl = window.location.href;
				console.log("currentUrl" + currentUrl);

				// https://dtc-mint.pinkribbons.club/?ref=0x6334BAE02114C080F05E6D58b65A1d7926FbbeBc

				let arr = currentUrl.split("=");
				console.log("arr:" + arr[1]);

				refAddress = arr[1];
				this.setState({ _refAddress : refAddress });	
				
			}
		} catch (err) {
			console.log(err);
		}

	}

	render() {

		return (

			<div class="allWrap">

				<div class="light">

					<div class="headers">

						<div class="headers2">

							<div class="logo"><img class="logoPic" src={logo} /></div>

							<div class="navBar">

							</div>
							<div class="right">

								<div class="icons">
									<div class="discord2"><img class="osPic" onClick={opensea} src={os} /></div>
								</div>
								<div class="connect2">
									{this.state.walletAddress === '' ?
										(<form onSubmit={this.walletConnect}>
											<button class="wallet2" >Wallet Connect</button>
										</form>) : (<form onSubmit={this.walletDisconnect}><button class="wallet2" >
											{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

								</div>



							</div>

						</div>

					</div>

					<div class="introduction">

						<div class="in2">
							<div class="intro">
								<img class="header" src={header} />
							</div>

							<div class="intro2">Almost exactly one year ago, at the end of June 2021, my wife was diagnosed with breast cancer. It was like driving into a wall at 300kmh. Since then, everything has changed. Unfortunately, I was not aware of this before, but the Austrian Cancer Aid provides such extraordinary services for those affected and their families and I thought how to support this. To distract myself I started to work with NFTs and I think this is a way to give something back. 80% of the proceeds will go to cancer support.</div>
							<Element name="mint">
								<div class="nftblockWalletConnectedALL">

									{this.state.walletAddress === '' ? (

										<div class="walletConnect">
											<form onSubmit={this.walletConnect}>
												<button class="wallet3" type='submit'>MINT NOW</button>
											</form>
										</div>
									) :
										(< div >

											<form onSubmit={this.freeMint}>
												<div class="placeAndWL">

													<input class="mintingAmount" type="text" name="setwlMintAmount" placeholder={

														this.state.walletAddress == this.state._owner ? (
															10000 - this.state.myNFTWallet
														) :

															(this.state._max_per_wallet - this.state.myNFTWallet)} value={this.state._wlMintAmount}
														onChange={event => this.setState({ _wlMintAmount: event.target.value })}></input>

													<button class="btnfos-0-4" type="submit">
														{this.state._wlMsg}</button>
												</div>
											</form>

										</div>)
									}
								</div>
							</Element>
						</div>
						{this.state.walletAddress === '' ?
							null : (

								(<div class="mintDiv">
									<div class="totalSupply">{this.state.totalSupply} / 10000</div>
									<div class="price"><div>Mint Price 10 MATIC</div></div>

									<div class="minting_count_button">
										<div class="center">
											<form onSubmit={this.onSubmitMinus}>
												<button class="btnfos-0-2" type="submit">-</button>
											</form>
										</div>

										<div>
											<div class="nftminter2">{this.state.nftMintingAmount}</div>
										</div>


										<div class="center">
											<form onSubmit={this.onSubmitPlus}>
												<button class="btnfos-0-2-2" type="submit">+</button>
											</form>
										</div>
									</div>

									<div class="mintbuttondiv">
										{this.state._mintStatus === true ? (
											<div class="wlDisplay">

												{this.state._FinalResult === true ? (
													<form onSubmit={this.whitelistMint}>
														<button class="btnfos-0-3" type="submit">
															Whitelist Mint</button>
													</form>
												) : (
													<form onSubmit={this.whitelistMint}>
														<button class="btnfos-0-3" type="submit">
															{this.state._wlMsg}</button>
													</form>)}
											</div>
										) : (<form onSubmit={this.onSubmit2}>
											<button class="btnfos-0-3" type="submit">
												{this.state._publicMintMsg}</button>
										</form>)}
									</div>
									<div>

										{this.state.statusError ? (
											<div class="errorMessage">
												<div >Sorry, something went wrong <br /> please try again later</div>
											</div>)
											: null}

										{this.state.statusLoading ? (
											<div class="loadingContainer">
												<div>
													<div class="loadingText">Minting your DTC NFT</div>
												</div>
											</div>)
											: null}

										{this.state.success ? (

											<div><div class="successfully">MINTING SUCCESSFUL!</div>
											</div>)
											: null}

									</div></div>

								)
							)}


					</div>
					<Carousel />

					<div class="referralMain">
						<div class="referralH">Get Your Referral Code</div>
						<div class="copyMain">
							<input readonly value={this.state._myRefLink}></input>

							<CopyToClipboard text={this.state._myRefLink}
								onCopy={() => this.setState({ copied: true })}>
								<img src={copy} />
							</CopyToClipboard>
						</div>
						{this.state.copied ? <span class="copyMessage"> Link Copied Successfully ✓</span> : null}

					</div>

				</div >

			</div >)
	}
}

export default App;
